import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import IndexPage from '../components/IndexPage';

interface IndexPageWrapperProps {
  data: {
    articles: {
      edges: any[];
    };
    popularArticles: {
      edges: any[];
    };
  };
}

const IndexPageWrapper: FC<IndexPageWrapperProps> = ({ data }) => {
  const latestPostDates = data.articles.edges.map(
    (edge) => edge.node.frontmatter.date,
  );
  const latestPostDate = latestPostDates[0];
  return (
    <Layout>
      <SEO
        title="Full-Stack Software Engineer and Developer Content Creator"
        indexPage
        slug=""
        keywords={[
          `oscar alsing`,
          `python`,
          `programming blog`,
          `programmer blog`,
          `backend developer blog`,
          `fullstack developer blog`,
          `developer blog`,
          `python blog`,
          `django blog`,
          `fastapi blog`,
        ]}
        pageDateUpdated={latestPostDate}
      />
      <IndexPage
        articles={data.articles.edges}
        popularArticles={data.popularArticles.edges}
      />
    </Layout>
  );
};

export const latestPostsQuery = graphql`
  {
    articles: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { regex: "/article|journal/" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 5
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            tags
            category
            date
          }
        }
      }
    }
    popularArticles: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { regex: "/article|journal/" }
          pastMonthViews: { ne: null }
        }
      }
      sort: { order: DESC, fields: [frontmatter___pastMonthViews] }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            tags
            category
            date
            pastMonthViews
          }
        }
      }
    }
  }
`;

export default IndexPageWrapper;
