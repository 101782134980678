import React, { FC } from 'react';
import { Link } from 'gatsby';
import * as styles from './IndexPage.module.scss';

interface IndexPageProps {
  articles: any[];
  popularArticles: any[];
}

const IndexPage: FC<IndexPageProps> = ({ articles, popularArticles }) => {
  return (
    <div>
      <div className={styles.introduction}>
        <h1 className={styles.title}>Hi, I'm Oscar.</h1>
        <h2>
          I'm a backend focused full-stack engineer who writes{' '}
          <Link to={'/articles/'}>articles</Link> about web development and
          journal about life.
        </h2>
      </div>
      <div className={styles.about}>
        <h2>Who am I?</h2>
        <p>
          See my <Link to={'/about/'}>about</Link> page.
        </p>
      </div>
      <div className={styles.about}>
        <h2>What am I doing now?</h2>
        <p>
          See my <Link to={'/now/'}>now</Link> page.
        </p>
      </div>
      <div className={styles.latestArticles}>
        <h2>Latest articles</h2>
        <Link to={'/articles/'}>
          <h2>↳ See all articles</h2>
        </Link>
      </div>
      <ul className={styles.articles}>
        {articles.map((article) => {
          return (
            <li key={article.node.frontmatter.slug}>
              <Link
                to={`/${article.node.frontmatter.slug}/`}
                className={styles.article}
              >
                <h3>{article.node.frontmatter.title}</h3>
              </Link>
            </li>
          );
        })}
      </ul>

      <div className={styles.popularArticles}>
        <h2>Most popular articles</h2>
      </div>
      <ul className={styles.articles}>
        {popularArticles.map((article) => {
          return (
            <li key={article.node.frontmatter.slug}>
              <Link
                to={`/${article.node.frontmatter.slug}/`}
                className={styles.article}
              >
                <h3>{article.node.frontmatter.title}</h3>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default IndexPage;
