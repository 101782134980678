// extracted by mini-css-extract-plugin
export var about = "IndexPage-module--about--98146";
export var article = "IndexPage-module--article--50611";
export var articles = "IndexPage-module--articles--02268";
export var black = "#000";
export var blue = "#1818e0";
export var blueLight = "#d2dfef";
export var breakpointLarge = "1201px";
export var breakpointMedium = "769px";
export var breakpointSmall = "501px";
export var green = "#1fd56b";
export var grey = "#b9bdc1";
export var greyDark = "#9ba0a3";
export var greyDarker = "#6e6e77";
export var greyLight = "#f1f1f1";
export var introduction = "IndexPage-module--introduction--8c26e";
export var latestArticles = "IndexPage-module--latestArticles--e380d";
export var popularArticles = "IndexPage-module--popularArticles--203a7";
export var red = "#f24822";
export var sizesLargeDesktop = "48px";
export var sizesLargeMobile = "28px";
export var sizesMediumDesktop = "24px";
export var sizesMediumMobile = "20px";
export var sizesSmallDesktop = "16px";
export var sizesSmallMobile = "12px";
export var sizesXSmall = "13px";
export var title = "IndexPage-module--title--7e301";
export var transitionDurationModalContent = "500ms";
export var transitionDurationModalOverlay = "200ms";
export var weightsBlack = "900";
export var weightsBold = "700";
export var weightsLight = "300";
export var weightsMedium = "500";
export var weightsRegular = "400";
export var weightsThin = "200";
export var white = "#fff";